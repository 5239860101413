.CPS1 {
  display: flex;
  flex-direction: row;
  font-size: 11px;
  align-items: center;
  justify-content: center;  
}
.CPS1_A1 {
  display: flex;
  flex: 1;
  margin-right: 5px;
}
.CPS1_A2 {
  display: flex;
  flex: 15  
}
