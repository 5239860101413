.LS {
  display: flex;
  flex-direction: column;
  background-color: rgba(17, 102, 138, 0.5);
  margin: 20px;
}

.A1_LS {
  display: flex;
  flex: 1;
  /* background-color: gray; */
  margin: 5px;
}

.A2_LS {
  display: flex;
  flex: 7;
  flex-direction: column;
  /* background-color: greenyellow; */
  margin-bottom: 10px;
}

.A3_LS {
  display: flex;
  flex: 3;
  /* background-color: lightsalmon; */
  /* border: 2px solid red; */
  color: rgb(158, 27, 50); /* red by itself does not show change with increased brightness*/
  margin: 5px;
  cursor: pointer;
  filter: brightness(100%);
}

.A21_LS {
  display: flex;
  flex: 1;
  /* background-color: rebeccapurple; */
  margin: 5px;
}

/*
.A21_LS:hover,
.A22_LS:hover {
  filter: brightness(70%);
}
*/

.A3_LS:hover {
  /* border-bottom: 1px solid var(--color-borderpink); */
  /* transition: .5s ease; */
  /* transform: scale(1.5); */ /* effective */
  filter: brightness(140%); /* somehow not effective*/
  /* text-decoration: underline; */
}

.A22_LS {
  display: flex;
  flex: 1;
  /* background-color: blueviolet; */
  margin: 5px;
}

.A211_LS,
.A221_LS {
  display: flex;
  flex: 1;
  /* background-color: blue; */
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.A212_LS,
.A222_LS {
  display: flex;
  flex: 30;
  /* background-color: green; */
  margin: 5px;
}

.A31_LS {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 5px;
  /* background-color: black; */
}
.A32_LS {
  display: flex;
  flex: 30;
  align-items: center;
  margin: 5px;
  /* background-color: turquoise; */
}

.A4_LS {
  display: flex;
  flex-direction: column;
  /* background-color: violet; */
  margin: 5px;
}