.CL1 {
  /* background-color: aqua; */
  height: 100%;
}

.CL_D1 {
  display: flex;
  flex-direction: row;
  height: 100%;
  /* background-color: yellow; */
  /* margin: 10px; */
  margin-bottom: 10px;
}
.CL_D2 {
  display: flex;
  flex-direction: column;
  font-size: 15px;  
  flex: 7;
  cursor: pointer;
}
.CL_D3 {
  display: flex;
  justify-content: left;
}
.CL_D4 {
  display: flex;
  justify-content: left;
  color: blue;
}
.CL_E1 {
  display: flex;
}
.CL_C1 {
  display: flex;
  flex-direction: column;
  /* background-color:blue; */
}

.CL_B1 {
  display: flex;
  flex-direction: row;
  height: 10vh;
  background-color: rgba(62, 135, 129, 0.5);
  border: 2px solid  rgba(62, 135, 129);;
}

.CL_B2 {
  display: flex;
  flex: 10;
  margin: 10px;
  /* background-color: lightsalmon; */
  align-self: center;
}
.CL_B2_1 {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 15px;
  opacity: 0.7;
}
.CL_B2_2 {
  display: flex;
  flex: 10;
  align-items: center;
  font-size: 30px;
  opacity: 0.7;
}

.CL_B33 {
  display: flex;
  flex: 2;
  /* background-color: lightsteelblue; */
}

.CL_B3,
.CL_B4 {
  display: flex;
  flex: 1;
  cursor: pointer;
  align-self: center;
  justify-content: center;
}
/* not effective
.CL_B4:hover {
  filter: brightness(140%);
}
*/

.CL_B3 {
  margin-left: 5px;
  margin-right: 2.5px;
}
.CL_B4 {
  margin-left: 2.5px;
  margin-right: 5px;
}

.CL_A1 {
  display: flex;
  flex-direction: row;
  /* background-color: red; */
  /* height: 100%; */
  margin: 5px;
}

.CL_A2 {
  display: flex;
  flex: 10;
  flex-direction: column;
  /* background-color: green; */
  height: 100vh;
  margin: 5px;
}

.CL_A3 {
  display: flex;
  flex: 3;
  /* background-color: blue; */
  margin: 5px;
}

.CL_D1 {
  display: flex;
  flex-direction: row;
}