.payForTier1__zero {
    display: flex;
    flex-direction: row;
    height: 100px;
    color: green;
    /*border: 1px solid green;*/
}

.payForTier1__one {
    display: flex;
    flex: 1;
    color: blue;
    background-color: antiquewhite;
    justify-content: center;
    align-items: center;
    border: 2px solid blue;
}

.payForTier1__two {
    display: flex;
    flex: 1;
    color: red;
    background-color: aliceblue;
    justify-content: center;
    align-items: center;
    border: 2px solid red;
}
