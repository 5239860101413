.YTBI {
  display: flex;
  flex-direction: column;
  background-color: brown;
  height: 100%;
}

.YTBI_A1 {
  display: flex;
  justify-content: center;
  margin: 20px;
  background-color: brown;
}

.YTBI_A2 {
  display: flex;
  background-color: blueviolet;
}