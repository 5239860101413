.fContainer_A1 {
  display: flex;
  flex-direction: column;
  /* width: 700px; */
  width: 100%;
  /* background: salmon; */
  background: gray;
  /* margin-left: 50px; */
  align-items: center;
  justify-content: center;
}

.fContainer {
  display: flex;
  flex-direction: column;
  /* width: 700px; */
  width: 100%;
  background: salmon;
  /* margin-left: 50px; */
  align-items: center;
  justify-content: center;
  margin-right: 50px;
}

.field {
  display: flex;
  align-items: center;
}

.RUF_A1 {
  display: flex;
  /* margin-left: 50px; */
  margin-top: 10px;
  background-color: green;
  /* width: 700px*/
  width: 100%;  
  justify-content: center;
}

.RUF_A2 {
  display: flex;
  flex-direction: column;
}

.RUF_A3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  margin: 30px;
  width: 80%;
}

.fFieldRadioRowContainer {
  display: flex;
  flex-direction: column;  
}

.messageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fLabel {
  display: inline-block;
  width: 200px;
  text-align: 'left';
  /* margin-top: 20px; */
  color: black;
  align-items: center;
}

.fLabelSubmit {
  display: flex;
  background: hsl(189, 75%, 75%);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 20px;
  min-width: 150px;
  /* width: 400px; */
}
.fLabelSubmit:hover {
  background: #00b4d8;
}
.submitSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fFieldRadioRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
.fFieldRadio {
  width: 50px;
}

.RUF_field {
  display: flex;
  color: black;
  margin: 10px;
}

.fField {
  width: 200px;
  color: black;
}

.fField1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ruFormContainer {
  display: flex;
  width: 600px;
  height: 600px;
  background-color: lightblue;
  color: green;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.formItem {
  display: flex;
  margin: 20px;
  width: 500px;
}

.formLabel {
  border: 2px solid black;
  width: 200px;
}

.formField {
  display: flex;
  border: 2px solid gray;
  justify-content: flex-end;
  width: 300px;
}

.formSubmit {
  display: flex;
  border: 2px solid red;
}