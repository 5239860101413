.talks_zero_container__PL {
  background-color: gray;
  height: 1300px;;
}

.talks_zero__PL {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.6rem;
  background-color: gray;
}

.talks_first__PL {
  flex: 2;
  transition: transform .25s;
}

.img__container__first__PL {
  cursor: pointer;
}

.talks_first__PL,
.talks_second__PL,
.talks_third__PL,
.talks_second_one__PL,
.talks_second_two__PL,
.talks_third_one__PL,
.talks_third_two__PL {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-content: center;
}

.img__container__PL {
  cursor: not-allowed;
}

.talks_first__PL:hover {
  transform: scale(1.1);
}

.talks_second__PL,
.talks_third__PL {
  /* cursor: not-allowed; */
}

/* .talks_first__PL, */
.talks_second__PL,
.talks_third__PL {
  height: 1200px;
}
.talks_second_one__PL,
.talks_third_one__PL {
  height: 420px;
}

.talks_second_two__PL,
.talks_third_two__PL {
  height: 600px;
}
.talks_second_one__PL,
.talks_second_two__PL,
.talks_third_one__PL,
.talks_third_two__PL {
  justify-content: flex-start;
}

.talks_first__PL img,
.talks_second__PL img,
.talks_third__PL img {
  height: 100%;
  width: 100%;
}

.overlay__talks_first__PL {
  font-size: xx-large;
  color: red;
  background: cyan;
  position: absolute;
  opacity: 0.7;
  right: 50%;
  bottom: 40%;
}

.overlay__topleft__PL {
  font-size: xx-large;
  color: red;
  background: cyan;
  position: absolute;
  opacity: 0.7;
}

.overlay__rightbottom__PL {
  font-size: large;
  color: red;
  background-color: cyan;
  position: absolute;
  opacity: 0.7;
  right: 0;
  bottom: 0;
}
.img__container__PL,
.img__container__first__PL {
  width: 100%;
}

.img__container__first__PL {
  position: relative;
  height: 186px;
}

.img__container__PL {
  position: relative;
  transition: transform .25s;
  height: 160px;
}

.img__container__PL:hover {
  transform: scale(1.04);
}

.textOne__PL {
  color: black;
  text-align: left;
  margin-bottom: 10px;
}
.textTwo__PL {
  color: #413839;
  text-align: left;
  margin-bottom: 10px;
}
.tagSA__PL {
  color: #440124;
  text-align: left;
}
.tagIOS__PL {
  color: #355e3b;
  text-align: left;
}
.tagRWA__PL {
  color: #1f456e;
  text-align: left;
}
.tagVD__PL {
  color: #413839;
  text-align: right;
}
.tagVOS__PL {
  color: #6f2da8;
  text-align: left;
}
.tagBUY__PL {
  color: #873600;
  text-align: left;
}
.tagHOST__PL {
  color: #87002f;
  text-align: left;
}
.one__PL {
  display: flex;
  flex-direction: column;
  width: 100%; /*500px;*/
}
.one__PL, .two__PL, .three__PL, .four__PL, .five__PL {
  background: lightslategray;
}
.one_one__PL {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.one_one_one__PL {
  display: flex;
  flex-direction: column;
}
.one_one_two__PL {
  align-items: flex-end;
  justify-content: center;
}

/* Tooltip container */
.tooltip__PL {
  position: relative;
  display: flex;
  /* border-bottom: 1px dotted black; */ /* If you want dots under the hoverable text */  
}
/* Tooltip text */
.tooltip__PL .tooltiptext__PL {
  visibility: hidden;
  width: 120px;
  background-color: green;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}  
/* Show the tooltip text when you mouse over the tooltip container */
.tooltip__PL:hover .tooltiptext__PL {
  visibility: visible;
}

/* spacing between three broad columns */
.space__PL {
  flex: 1;
}
.talks_first__PL,
.talks_second__PL,
.talks_third__PL {
  flex: 7;
}

@media screen and (max-width: 450px) {
  .talks_first__PL img,
  .talks_second__PL img,
  .talks_third__PL img {
      height: 100%;
      width: 100%;
  }    
  .talks_zero_container__PL {
      height: 3200px;
  }
  .talks_zero__PL {
      height: 2500px;
  }
  /*.one__PL,*/
  .textOne__PL,
  .textTwo__PL,
  .textThree__PL
  {
      width: 300px;
  } 
  .talks_first__PL {
      height: 600px;
  }
  .talks_first__PL:hover {
      transform: scale(1);
  }
  .img__container__PL:hover {
      transform: scale(1);
  }
}