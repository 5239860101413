.dropdown-item {
  padding: 0.5rem;
  margin: 0.1rem;
  width: 100%;
  border-radius: 0.5rem;
  cursor: pointer;
  z-index: 1;
}

.dropdown-item:hover {
  background-color: rgb(240 249 255);
}
