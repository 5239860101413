.talks__zero {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: '15px'; */
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border: 2px solid black; */
    /* padding: 50px 50px 50px 50px; */
    gap: 0.6rem;
    background-color: gray;
    /*margin-bottom: 2rem;*/
}

.talks__first {
    flex: 2;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    /* border-color: black; */
    /* background-color: lightgreen; */
    text-align: center;
    /* margin-top: 5px; */
    /* margin-bottom: 5px; */
    /* border: 2px solid black; */
    display: flex;
    flex-direction: column;
    transition: transform .25s;
    cursor: pointer;
}

.talks__first:hover {
    transform: scale(1.1);
}

.talks__second {
    flex: 1;
    /* color: 'red'; */
    /* background-color: lightsalmon; */
    text-align: center;
    /* border: 2px solid black; */
    /* margin-bottom: 30px; */
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    cursor: pointer;
}

.talks__third {
    flex: 1;
    /* background-color: lightblue; */
    text-align: center;
    /* border: 2px solid black; */
    /* margin-bottom: 30px; */
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    cursor: pointer;
}

.talks__first img,
.talks__second img,
.talks__third img {
    height: 100%;
    width: 100%;
}

.overlay__talks__first {
    font-size: xx-large;
    color: red;
    position: absolute;
    opacity: 0.7;
    right: 50%;
    bottom: 40%;
}

.overlay__topleft {
    font-size: xx-large;
    color: red;
    position: absolute;
    opacity: 0.7;
}

.overlay__rightbottom {
    font-size: large;
    color: blue;
    position: absolute;
    opacity: 0.7;
    right: 0;
    bottom: 0;
}

.img__container__first {
    border: 2px solid black;   
    position: relative;
}

/*
.img__container__first img{
    object-fit: contain;
}
*/

.img__container {
    border: 2px solid black;   
    position: relative;
    transition: transform .25s;
}

.img__container:hover {
    transform: scale(1.04);
}