.genAI_1 {
  display: flex;
  flex-direction: column;
  background-color: rgba(235, 106, 205, 0.3);
  margin: 20px;
  padding: 10px;
}

.genAI_2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(150, 176, 15, 0.4);
  border: 2px solid rgb(150, 176, 15);
  margin: 20px;
  padding: 2px;
}

.genAI_3 {
  display: flex;
  flex-direction: column;
  background-color: rgba(150, 176, 15, 0.4);
  text-decoration: underline;
  font-weight: 800;
  font-size:larger;
  padding-bottom: 5px;
}

.genAI_4 {
  display: flex;
  flex-direction: column;
  background-color: rgba(150, 176, 15, 0.4);
  font-weight: 800;
  font-size:medium;
  padding: 5px;
}

.genAI_5 {
  display: flex;
  flex-direction: column;
  background-color: rgba(150, 176, 15, 0.4);
  margin: 20px;
  padding: 30px;
}