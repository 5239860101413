@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');
.EPDETBL {
  display: flex;
  flex-direction: column;
}

.EPDETBL_A1 {
  display: flex;
  flex-direction: column;
}

.EPDETBL tr {
  display: flex;
}

.EPDETBL td {
  display: flex;
  font-size: 1.6rem;
  line-height: 1.6;
  border: 1px solid rgba(68, 60, 104);
  min-width: 20rem;
  padding: 1.5rem;
  line-height: 1.7;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

.saved_date_val,
.last_action_val,
.type_val,
.more_val {
  min-width: 20rem;
}

.id_val{
  min-width: 50rem;  
}

.more_val {
  cursor: pointer;
}
.more_val:hover {
  border: 1px solid var(--color-borderpink);
}
