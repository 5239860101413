.EPCLASS {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 128, 0, 0.5);  
}

.EPCLASS_4 {
  display: flex;
  flex-direction: row;
}

.EPCLASS_1 {
  display: flex;
  flex-direction: column;
  background-color: rgba(100, 128, 0, 0.5);
  justify-content: left;
  align-items: center;
  margin: 5px;
  padding: 5px;
}


.EPCLASS_2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(200, 128, 0, 0.5);
  justify-content: left;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid green;
}

.EPCLASS_2:hover {
  filter: brightness(120%);
}

.EPCLASS_3 {
  display: flex;
  flex-direction: column;
  background-color: rgba(256, 128, 0, 0.5);
  margin-top: 20px;
}

.EPCLASS_4 {
  display: flex;
  flex-direction: row;
  background-color: rgba(256, 128, 0, 0.5);
  justify-content: space-between;
}

.EPCLASS10 {
  display: flex;
  flex-direction: row;
  background-color: rgba(128, 0, 128, 0.5);
  justify-content: space-between;
}

.EPCLASS11 {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 100, 128, 0.5);
  margin: 5px;
  padding: 5px;
}

.EPCLASS12 {
  display: flex;
  flex-direction: column;
  background-color: rgba(128, 200, 128, 0.5);
  cursor: pointer;
  margin: 5px;
  padding: 5px;
}