.CL {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 10px;
  border: 2px solid rgba(17, 102, 138);
}

.A4_CL {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-color: rgba(17, 102, 138, 0.5);
  /* margin: 10px; */
  margin-bottom: 20px;
  margin-top: 10px;
}
.A41_CL {
  display: flex;
  flex: 6;
  font-size: 25px;
  /* background-color: brown; */
  padding: 20px;
  justify-content: left;
  align-self: center;  
}
.A41_CL_1 {
  display: flex;
  flex: 2;
  align-items: center;
  font-size: 15px;
  opacity: 0.7;
}
.A41_CL_2 {
  display: flex;
  flex: 10;
  align-items: center;
  font-size: 25px;
}
.A42_CL {
  display: flex;
  flex: 2;
  flex-direction: row;
  /* background-color: red; */
}
.A421_CL {
  display: flex;
  flex: 1;
  /* background-color: maroon; */
  align-self: center;
  justify-content: center;
}
/* not effective
.A421_CL:hover {
  filter: brightness(140%);
}
*/

.A422_CL {
  display: flex;
  flex:1;
  /* background-color: saddlebrown; */
  align-self: center;
  justify-content: center;
}

.A5_CL {
  flex: 5;
  background-color: rgba(128, 128, 128, 0.5);
  margin-bottom: 20px;
}

.A6_CL {
  flex: 3;
  /*background-color: brown;*/
  margin-bottom: 10px;
}
