.app__headerFU {
  background-color: #525052;
  /* background-color: var(--color-black); */
  /* border: 1px solid var(--color-golden); */
  border-bottom: 2px solid var(--color-golden);
}

.social-container2 {
  display: flex;
  justify-content:space-evenly;
  align-items: center;  
  margin: 4rem 0 3rem 0;
}

.social-container2__item1 {
  flex: 3
}
.social-container2__item2 {
  flex: 1
}
.social-container2__item3 {
  flex: 6
}

a.social {
  margin: 0 0.5rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: purple;
}
