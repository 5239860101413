.TI {
  display: flex;
  flex-direction: column;
  background-color: brown;
  margin: 5px;
  height: 80px;
  width: 80px;
  align-self: center;
  justify-content: center;  
  cursor: pointer;
}

.TI:hover {
  filter: brightness(140%);
}

.NotYetAvailable {
  filter: brightness(50%);
}

.TI_A1 {
  display: flex;
  flex: 1;
  background-color: gray;  
  align-self: center;
  justify-content: center;  
  align-items: center;
}

.TI_A2 {
  display: flex;
  flex: 1;
  font-size: 12px;
  background-color: turquoise;  
  align-self: center;
  align-items: center;
  justify-content: center;  
}

