@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

html {
  font-size: 62.5%;
}

table {
  margin: 5rem auto;
  border-radius: 2rem;
  border: 1rem hidden #443C68;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px black;
  overflow: hidden;
  max-width: 140rem; /* sum of all child td*/
}

table td {
  display: flex;
}

.handleClick {
  cursor: pointer;
}
.handleClick:hover {
  border: 1px solid var(--color-borderpink);
}

table td img {
  width: 70px;
  height: 70px;
  margin: 2px;
}

li, p, a, td {
  font-size: 1.6rem;
  line-height: 1.6;
}

thead {
  font-size: 1.8rem;
  background-color: rgba(68, 60, 104, 1);
  color: #fff;
}

th,
td {
  border: 1px solid rgba(68, 60, 104);
  min-width: 12.5rem;
  padding: 1.5rem;
  line-height: 1.7;
}

td {
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

tbody {
  min-height: 200px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.EPPCO,
.EPPCO_1 {
  display: flex;
  flex-direction: column;
  /*background-color: rgb(49, 125, 49, 0.5);*/
  justify-content: center;
  align-items: center;
  max-width: 100rem;
}
.EPPCO_1 {
  overflow: scroll;
}

.EPPCO_A1 {
  display: flex;
  flex-direction: column;
  background-color: rgb(161, 70, 70);
}

.saved_date_header,
.last_action_header,
.type_header,
.more_header {
  min-width: 20rem;
}

.id_header{
  min-width: 50rem;  
}
