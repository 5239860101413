.LESSON_VDO {
  display: flex;
  flex-direction: column;
  background-color: rgba(130, 40, 0, 0.5);
  margin: 20px;
}
.LESSON_VDO_1 {
  display: flex;
  flex-direction: column;
  background-color: rgba(160, 60, 100, 0.5);
}

.LESSON_VDO_2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(190, 80, 200, 0.5);
  align-items: center;
  margin: 5px;
  height: 500px;
  overflow: scroll;
}

.LESSON_VDO_3 {
  display: flex;
  flex-direction: column;
  background-color: rgba(220, 100, 250, 0.5);
}