.ACG {
  display: flex;  
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: rgba(0, 255, 255, 0.2); */
  width: 100%;
}

.ACG_A1 {
  display: flex;
  /* background-color: blueviolet; */
  margin: 1px;
}

.ACG_Header {
  display: flex;
  background-color: rgba(77, 7, 163, 0.5);
  margin: 1px;
  width: 100%;
  font-size: 13px;
}

.ACG_Header_A1 {
  display: flex;
  flex: 1;
  /* background-color:burlywood; */
  margin: 1px;
}

.ACG_Header_A2 {
  display: flex;
  flex: 1;
  /* background-color: red; */
  margin: 1px;  
  justify-content: flex-end;
  align-self: center;
  margin-right: 5px;
}

.ACG_Body {
  display: flex;
  flex-wrap: wrap;
  /* background-color: rgba(165, 42, 42, 0.5); */
  margin: 5px;
  width: 100%;
}

.ACG_Body_A1 {
  display: flex;
  flex-wrap: wrap;
  /* background-color: magenta; */
  margin: 1px;
}