  .card-container {
    min-width: 300px;
    max-width: 300px;
    /* width: 15rem; */
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 1rem;
    background-color: white;
    height: fit-content;
    transition: transform .2s;
  }

  .card-container:hover {
    cursor: crosshair;
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    /*
    transform: translateY(-0.5rem);
    box-shadow: 0.5rem 0.75rem 1.5rem #bbbbbb;
    */
  }
            
  .card-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .card-title {
    margin: 0.5rem 5%;
  }
  
  .card-description {
    margin: 0.5rem 5%;
  }
  
  .card-btn {
    text-align: center;
    width: 90%;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: #2563eb;
    color: white;
    text-decoration: none;
    margin: 0.5rem 0.5rem;
    cursor: pointer;
  }

  .displayStack__1,
  .displayStack__2{
  margin: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productPrice{
  font-size: 1.2rem;
  /* font-weight: bold; */
  cursor: pointer;
  border: 2px solid green;
  background-color: #24a0ed;
  border-radius: 12%;
}

.productSales,
.productTime{
  font-size: 1.1rem;
}

.features {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-right: 1rem;
  background-color: lightblue;
}

.ulist {
  list-style-type: none;
  width: 250px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

.litem {
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: ridge;
  border-color: gray;
  color: green;
  margin-left: 1rem;
  margin-top: 0.5rem
}

.faicon {
  color: purple;
  margin-right: 8px;
}