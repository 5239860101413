.app__header {
    /* background-color: var(--color-black);*/
    /* border: 1px solid var(--color-golden); */
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

.swiftLogo {
    display: flex;
    gap: 1rem;
}

.buildWithUs_Container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.buildWithUs {
    display: flex;
    flex: 4;
    flex-direction: column;
    width: 100%;
}

.buildWithUs1 {
    color: #3e8ede;
}

.buildWithUs2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;  
}

.buildWithUs3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.policyContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}
.policyTrack {
    font-size: 15px;
    align-items:flex-end;
    font-family: var(--font-base);
    color: var(--color-golden);
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.exploreTrack {
    font-size: 25px;
    align-items: center;
    font-family: var(--font-base);
    color: var(--color-golden);
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.exploreTrack2 {
    font-size: 25px;
    align-items: center;
    font-family: var(--font-base);
    color: var(--color-golden);
    display: flex;
    flex-direction: row;
    cursor: not-allowed;
}

.angleUpLogo {
    color: blue;
    font-size: 30px;
    cursor: pointer;
}

.angleRightLogo {
    color: var(--color-golden);;
    font-size: 30px;
    cursor: pointer;
    margin-left: 5px;
}

.angleRightLogo2 {
    color: var(--color-golden);;
    font-size: 30px;
    cursor: not-allowed;
    margin-left: 5px;
}

.envelopeLogo {
    color: var(--color-golden);;
    font-size: 30px;
    cursor: pointer;
    margin-left: 10px;
}

/* Tooltip container */
.tooltip__HR {
    position: relative;
    display: inline-block;
    font-size: 10px;
    /* border-bottom: 1px dotted black; */ /* If you want dots under the hoverable text */  
}
/* Tooltip text */
.tooltip__HR .tooltiptext__HR {
    visibility: hidden;
    width: 120px;
    background-color: green;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip__HR:hover .tooltiptext__HR {
    visibility: visible;
  }

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}
