.TILST {
  display: flex;
  flex-direction: column;
  background-color: brown;
  margin: 5px;
  height: 80px;
  width: 210px;
  align-self: center;
  justify-content: center;  
  cursor: pointer;
}

.TILST:hover {
  filter: brightness(140%);
}

.NotYetAvailable {
  filter: brightness(50%);
}

.TILST_A1 {
  display: flex;
  flex: 1;
  background-color: gray;  
  align-self: center;
  justify-content: center;  
  align-items: center;
}

.TILST_A2 {
  display: flex;
  flex: 1;
  width: 100%;
  font-size: 12px;
  background-color: turquoise;  
  align-self: center;
  align-items: center;
  justify-content: center;  
}

.CITLST {
  display: flex;
  flex-direction: column;
  background-color: brown;
  margin: 5px;
  height: 80px;
  width: 210px;
  align-self: center;
  justify-content: center;  
  cursor: pointer;
}

.CITLST:hover {
  filter: brightness(65%);
}

.NotYetAvailable {
  filter: brightness(50%);
}

.CITLST_A1 {
  display: flex;
  flex: 1;
  background-color: gray;  
  align-self: center;
  justify-content: center;  
  align-items: center;
}

.CITLST_A2 {
  display: flex;
  flex: 1;
  width: 100%;
  font-size: 12px;
  background-color: turquoise;  
  align-self: center;
  align-items: center;
  justify-content: center;  
}


