* {
  box-sizing: border-box;
  --lesson-color: purple;
  --lesson-text-color: #720e9e;
  --lesson-row-color: #DDA0DD;
  --lesson-btn-newentry-color: maroon;
  --lesson-btn-cancel-color: red;
  --lesson-btn-save-color: green;
  --lesson-btn-cancel-color-bgd: #720e9e;
  --lesson-btn-save-color-bgd: #720e9e;
}

.App__L {
  width: 70vh;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
}

.form-field__L {
  flex: 2;
  /* background-color: var(--lesson-color); */
  background-color: rgba(255, 0, 255, 0.3);
  width: 70%;
  padding: 10px;
  /* border: 2px solid magenta; */
}

.Output__L {
  margin-left: 3rem;
  flex: 1;
}

.Output__L ul {
  list-style-type: none;
}

.form-field__L label, h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: rgb(84, 84, 84);
}

.input__L {
  height: 35px;
  width: 100%;
  padding: 7px;
  outline: none;
  /* border-radius: 5px;*/
  /* border: 1px solid rgb(220, 220, 220);*/
}
.input__L:focus {
  border: 1px solid rgb(0, 208, 255);
}

.services__L {
  display: flex;
  justify-content: space-between;
}

.section-container__L {
  display: flex;
  flex-direction: column;
}

.division-container__L {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  /* background-color: var(--lesson-row-color);*/
}

.aa__L {
  /* background-color: yellow; */
}

.first-division__L {
  display: flex;
  flex-direction: column;
  /* margin: 0 5px 0.5rem 0; */
  margin: 0.25rem;
  width: 100%;
  /* background-color: yellow; */
}
.first-division__L_1 {
  font-size: 17px;
  color: white;
  justify-content: left;
  display: flex;
  cursor: pointer;
  padding-left: 10px;
  border-bottom: 1px solid magenta;
  border-left: 1px solid magenta;
}


.first-division__L .text {
  color: var(--lesson-text-color);
  font-size: 16px;
}

.unavailable_btn {
  display: flex;
  flex-direction: column;
  color: salmon;
  cursor: not-allowed;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  background: none;
  border-radius: 5px;
  border: 1px solid salmon;
}

.add-btn__unavailable {
  padding: 5px;
  cursor: not-allowed;
  background: none;
  border: 1px solid salmon;
}
.add-btn__unavailable span {
  color: salmon;
  margin-left: 5px;
}
.add-btn__L {
  padding: 5px;
  cursor: pointer;
}
.add-btn__L span {
  margin-left: 5px;
}
.add-btn__L:hover {
  border: 1px solid var(--lesson-color);
  filter: brightness(140%);
}

.button__L {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}

/* .first-division__L button, */
.add-entry__L button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  color: var(--lesson-btn-newentry-color); /* rgb(0, 208, 255);*/
  /* border: 1px solid var(--lesson-btn-newentry-color);*/ /* rgb(0, 208, 255); */
}

.second-division__L {
  display: flex;
  margin: 5px;
}

second-division__L button {
  color: red;
  /* border: 1px solid red; */
}

.save-remove-btn__L {
  display: flex;
  flex-direction: row;
}

.save-remove-btn__L .remove-btn__L,
.save-remove-btn__L .remove-btn__L_2 {
  color: var(--lesson-btn-cancel-color);
  /* border: 1.5px solid var(--lesson-color); */
}
.processing__L {
  cursor: not-allowed;
  color: darkgray;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
}
.save-remove-btn__L .save-btn__L,
.save-remove-btn__L .save-btn__L_2 {
    color: var(--lesson-btn-save-color);
  /* border: 1.5px solid var(--lesson-color); */
}

.remove-btn__L,
.remove-btn__L_2 {
  color: var(--lesson-btn-cancel-color);
  /* border: 1px solid var(--lesson-btn-cancel-color); */
  /* background-color: var(--lesson-btn-cancel-color-bgd); */
  margin-left: 5px;
}

.save-btn__L,
.save-btn__L_2 {
  color: var(--lesson-btn-save-color);
  /* border: 1px solid var(--lesson-btn-save-color); */
  /* background-color: var(--lesson-btn-save-color-bgd); */
  margin-left: 5px;
}

.save-btn__L,
.remove-btn__L,
.remove-btn__L_2,
.save-btn__L_2 {
  cursor: pointer;
}
