.CSCOM {
  display: flex;
  flex-direction: row;
  /* background-color: brown; */
  /* margin: 5px; */
  /* height: 80px; */
  /* width: 80px; */
  align-self: center;
  justify-content: center;
  cursor: pointer;
  filter: brightness(70%);
}

.CSCOM:hover {
  filter: brightness(90%);
}

.CSCOM_A1 {
  display: flex;
  flex: 1;
  /* background-color: gray;  */
  align-self: center;
  justify-content: center;  
  align-items: center;
}

.CSCOM_A2 {
  display: flex;
  flex: 1;
  /* font-size: 12px; */
  /* background-color: turquoise;  */
  /* align-self: center; */
  align-items: center;
  justify-content: left;  
  /* color: brown; */
  min-width: 150px;
}

.CSCOM_A3 {
  display: flex;
  flex: 1;
  /* background-color: blue; */
  justify-content: left;
  align-items: center;
  margin: 5px;
}
