.ST {
  display: flex;
  flex-direction: column;
  /* background-color: gray; */
}

.ST_A1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  /* background-color: lightgray; */
  align-self: center;
  width: 500px;
  height: 500px;
  opacity: 0.5;
  margin-bottom: 20px;
  border: 20px solid rgba(128, 128, 128, 0.5);
}

.ST_A2 {
  display: flex;
  flex: 2;
  flex-direction: column;
  /* background-color: brown; */
  align-self: center;
}

.ST_A3 {
  display: flex;
  flex: 2;
  flex-direction: row;
  margin: 10px;
  /* background-color: lightcyan; */
  align-self: center;
  cursor: pointer;
}

.ST_A3:hover {
  border-bottom: 1px solid var(--color-borderpink);
  /* transition: .5s ease; */
  /* filter: brightness(140%); */ /* not effective*/
}

.ST_A31 {
  display: flex;
  flex: 1;
  /* background-color: black; */
  align-self: center;
  margin: 2px;
}

.ST_A32 {
  display: flex;
  flex: 10;
  /* background-color: cadetblue; */
  align-self: center;
  margin: 2px;
}
