.VDLI_1 {
  display: flex;
  flex-direction: row;
  color: darkgoldenrod;
}

.VDLI_2,
.VDLI_3 {
  display: flex;
  flex: 10;
  align-items: center;
}

.VDLI_2 {
  justify-content: right;
  margin-right: 10px;
}
.VDLI_3 {
  justify-content: left;
  margin-left: 10px;
}
