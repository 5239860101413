.PUF_0 {
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 128, 0, 0.3);
  height: 800px;
  justify-content: center;
  align-items: center;
}
.PUF_1 {
  display: flex;
  flex-direction: row;
  background-color: rgba(128, 0, 128, 0.5);
  height: 500px;
}

.PUF_2 {
  display: flex;
  flex-direction: column;
  flex: 15;
  background-color: rgba(128, 0, 0, 0.5);
}

.PUF_3 {
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 10px;
}

.PUF_4 {
  display: flex;
  flex-direction: column;
  background-color: rgba(105, 73, 14, 0.5);
  text-align: center;
  margin: 10px;
}

.PUF_5 {
  display: flex;
  flex-direction: column;
  flex: 30;
  margin: 20px;
}

.mb-3,
.mb-submit {
  display: flex;
  flex-direction: column;
  margin: 5px;
  width: 500px;
}

.mb-submit {
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.form-label {
  display: flex;
  flex-direction: column;
}

.form-control {
  display: flex;
  flex-direction: column;
}

.form-check {
  display: flex;
  flex-direction: column;
}

.form-check-input {
  display: flex;
  flex-direction: column;
  width: 500px;
}

.form-check-label {
  display: flex;
  flex-direction: column;
}