.MT {
  display: flex;  
  flex-direction: row;
  flex-wrap: wrap;
  /* background-color: aqua; */
  width: 100%;
}

.MT_A1 {
  display: flex;
  /* background-color: blueviolet; */
  margin: 1px;
}

.MT_Header {
  display: flex;
  background-color: rgba(77, 7, 163, 0.5);
  margin: 1px;
  width: 100%;
  font-size: 13px;
}

.MT_Header_A1 {
  display: flex;
  flex: 1;
  /* background-color:burlywood; */
  margin: 1px;
}

.MT_Header_A2 {
  display: flex;
  flex: 1;
  /* background-color: red; */
  margin: 1px;  
  justify-content: flex-end;
  align-self: center;
  margin-right: 5px;
}

.MT_Body {
  display: flex;
  flex-wrap: wrap;
  /* background-color: brown; */
  margin: 1px;
  width: 100%;
}

.MT_Body_A1 {
  display: flex;
  flex-wrap: wrap;
  /* background-color: magenta; */
  margin: 1px;
}