.videoCardContainer{
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem 1rem 2rem;
}

.vcOne {
    flex:1;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    /*border: 2px solid yellow;*/
    background-color: gray;
}

.vcTwo {
    flex:1;
    display: flex;
    background-color: gray;
}

.vcThree {
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.vcFour {
    flex:1;
    display: flex;
    justify-content: space-evenly;    
}

.vcTwoOne {
    flex:1;
    display: flex;
    /*border: 2px solid magenta;*/
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.vcTwoTwo {
    flex:1;
    display: flex;
}

.vcTwoTwoVideoElement {
    display: flex;
    width: 1040px;
    height: 480px;
    margin-top: 2rem;
    flex-direction: column;
}

.vcTwoTwoVideoElementNotAllowed {
    display: flex;
    width: 1040px;
    height: 480px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-right: 1rem;
    margin-left: 1rem;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 2px solid coral;
}

.lockImage {
    font-size: 50px;
    color: lightcoral;
    justify-content: 'center';
    align-items: center;
    justify-content: center;
}

.vcThreeOne {
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;    
    text-align: center;
    max-width: 1000px;
}

.vcThreeTwo {
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 1000px;
    /*margin-left: 18rem;
    margin-right: 18rem;*/
    
}

.vcFourOne {
    flex:3;
    display: flex;
    flex-direction: column;
    /*
    margin-left: 2rem;
    margin-right: 2rem;*/
    margin-bottom: 2rem;
}

.vcFourTwo {
    flex:0.5;
    display: flex;
    /*
    margin-left: 4rem;
    margin-right: 2rem;*/
    color: peru;
    /*margin-bottom: 2rem;*/
}

.vcFourThree {
    flex:2;
    display: flex;
    flex-direction: column;
    /*
    margin-left: 4rem;
    margin-right: 2rem;*/
    color: peru;
    margin-bottom: 2rem;
}

.vcFourOneOne {
    flex:1;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.vcFourOneTwo {
    flex:1;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

.vcFourOneThree {
    flex:1;
    display: flex;
}

.vcFourOneOneOne {
    flex:1;
    display: flex;
    color: peru;
}

.vcFourOneOneTwo {
    flex:1;
    display: flex;
}

.vcFourOneTwoOne {
    flex:1;
    display: flex;
    color: peru;
}

.vcFourOneTwoTwo {
    flex:1;
    display: flex;
}

.vcTwoOneOne {
    flex: 1;
    /*border: 2px solid yellow;*/
}
.vcTwoOneTwo {
    flex: 5;
    display: flex;
    border-top: 2px solid gold;
    margin-left: 2rem;
    margin-right: 2rem;
    /*border: 2px solid yellow;*/
    flex-direction: column;
}
.vcTwoOneThree {
    flex: 1;
    /*border: 2px solid yellow;*/
}
.vcTwoOneTwoItemsHead {
    flex: 1;
    display: flex;
    color: maroon;
    /*border-bottom: 2px solid gold;*/
    align-items: center;
    justify-content:space-between;
    border-bottom: 1px solid darkgray;
}
.vcTwoOneTwoItems {
    flex: 1;
    display: flex;
    /*border-bottom: 2px solid gold;*/
    align-items: center;
    justify-content:space-between;
    border-bottom: 1px solid darkgray;    
}

.vcFourThreeItemHead {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    color: lightblue;
}

.vcFourThreeItem {
    display: flex;
    flex-direction: row;
    font-size: 20px;
    margin: 0.5rem 0.5rem;
}

.vcFourThreeItemIcon {
    height: 50px;
    width: 50px;
    color: lightcoral;
    justify-content: 'center';
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.vcFourThreeItemOneText {
    color: black
}

@media screen and (max-width: 650px) {
    .vcTwo {
        flex:1;
        display: flex;
        flex-direction: column;
    }        

    .vcFour {
        flex:1;
        display: flex;
        flex-direction: column;
    }    
}