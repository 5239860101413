* {
  box-sizing: border-box;
  --section-color: green;
  --section-text-color: #088F8F;
  --section-row-color: lightgreen;
  --section-btn-newsection-color: #454B1B;
  --section-boundary-color: #444C38;
  --section-btn-cancel-color: red;
  --section-btn-save-color: green;
  --section-btn-cancel-color-bgd: #4FFFB0;
  --section-btn-save-color-bgd: #4FFFB0;
}

.AS_C1 {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.AS_A1 {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-bottom: 30px;
}
.AS_A2 {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.AS_A5 {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.AS_A4 {
  display: flex;
  font-size: 25px;
  margin: 5px;
  justify-content: center;
}
.AS_A3 {
  display: flex;
  flex: 2;
  align-self: center;
  justify-content: center;
  text-align: center;
  width: 600px;
}

.App {
  width: 70vh;
  margin: 5rem auto;
  display: flex;
  justify-content: space-between;
}

.form-field {
  flex: 2;
  /* background-color: green; */
}

.Output {
  margin-left: 3rem;
  flex: 1;
}

.Output ul {
  list-style-type: none;
}

.form-field label, h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: rgb(84, 84, 84);
}

.input {
  height: 35px;
  width: 100%;
  padding: 7px;
  outline: none;
  border-radius: 5px;
  /* border: 1px solid rgb(220, 220, 220);*/
}
.input:focus {
  border: 1px solid rgb(0, 208, 255);
}

.services {
  display: flex;
  justify-content: space-between;
}

.section-container {
  display: flex;
  flex-direction: column;
}

.division-container-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* background-color: var(--section-color); */
  border: 3px solid var(--section-boundary-color);
  background-color: rgba(0, 128, 0, 0.35);
  margin-bottom: 20px;
}

.division-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 2px solid var(--section-boundary-color); */
  padding: 5px;
  /* background-color: var(--section-row-color); */
}

.first-division {
  display: flex;
  flex-direction: column;
  /* margin: 0 5px 0.5rem 0; */
  margin: 0.25rem;
  width: 100%;
  flex: 10;
}

.unavailable_section {
  display: flex;
  flex-direction: column;
  color: salmon;
  cursor: not-allowed;
  margin: 5px;
  align-items: center;
  justify-content: center;
}
.first-division_1 {
  font-size: 20px;
  color: white;
  justify-content: left;
  display: flex;
  cursor: not-allowed;
  padding-left: 10px;
  border-bottom: 2px solid green;
  border-left: 2px solid green;
}

.first-division .text {
  color: var(--section-text-color);
}

.unavailable3 {
  padding: 5px;
  flex-direction: row;
  cursor: not-allowed;;
  align-items: center;
  justify-content: center;
}
.unavailable3 span {
  margin-left: 5px;
  align-items: center;
  justify-content: center;
}

.add-btn {
  padding: 5px;
  cursor: pointer;
}
.add-btn span {
  margin-left: 5px;
}
.add-btn:hover {
  border: 1px solid var(--section-btn-newsection-color);
  filter: brightness(140%);
}

.button {
  background: none;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 2px;
  padding: 5px 10px;
}

.unavailable2 button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  color: salmon;
  cursor: not-allowed;
  background: none;
  border: 1px solid salmon;
  justify-content: center;
}
.first-division button, 
.add-entry button {
  align-self: flex-start;
  display: flex;
  align-items: center;
  /* margin-top: 0.5rem; */
  color: var(--section-btn-newsection-color); /* rgb(0, 208, 255);*/
  /* border: 1px solid var(--section-btn-newsection-color);*/ /* rgb(0, 208, 255); */
}
.add-entry2 {
  background-color: rgba(0, 128, 0, 0.5);
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
}

.second-division {
  display: flex;
  margin: 5px;
  flex: 3;
  justify-content: right;
}
second-division button {
  color: red;
  /* border: 1px solid red; */
}

.save-remove-btn {
  display: flex;
  flex-direction: row;
}

.remove-btn {
  color: var(--section-btn-cancel-color);
  /* border: 1px solid var(--section-btn-cancel-color); */
  /* border: 1.5px solid var(--section-color); */
  margin-left: 5px;
  /* background-color: var(--section-btn-cancel-color-bgd); */
  cursor: pointer;
}

.processing-btn {
  color: darkgray;
  /* border: 1px solid var(--section-btn-save-color); */
  /* border: 1.5px solid var(--section-color); */
  margin-left: 5px;
  /* background-color: var(--section-btn-save-color-bgd); */
  cursor: not-allowed;
}

.save-btn {
  color: var(--section-btn-save-color);
  /* border: 1px solid var(--section-btn-save-color); */
  /* border: 1.5px solid var(--section-color); */
  margin-left: 5px;
  /* background-color: var(--section-btn-save-color-bgd); */
  cursor: pointer;
}

.unavailable {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
}

.ASECT_A1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px;
}

.ASECT_A2,
.ASECT_A5 {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.ASECT_A3,
.ASECT_A4 {
  display: flex;
  justify-content: left;
}
.ASECT_A6,
.ASECT_A7 {
  display: flex;
  justify-content: right
}
.ASECT_A4,
.ASECT_A7 {
  color: blue;
}
