@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");

:root {
    --body-bg: #85a6f4;
    --box-bg: #a45757;
    --input-bg: #4a4d55;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #4267b2;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.VM {
  display: flex;
  flex-direction: column;
  background-color: lightslategray;
}
.VM_A1 {
  display: flex;
  font-size: 25px;
  margin-bottom: 10px;
}

.VM_A2 {
  display: flex;
  font-size: 15px;
  text-align: left;
  margin-bottom: 20px;
}

.VM_A3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.VM_A4 {
  background-color: var(--box-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.VM_A5 {
  margin-bottom: 30px;
  text-align: center;
}