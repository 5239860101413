.flowBar {
  display: flex;
  justify-content: center;  
  height: 60px;
  /* background-color: rgba(128, 128, 128, 0.6); */
  position: fixed;
  bottom: 20px;
  left: 80%;
  transform: translate(-50%, -50%);
  align-items: center;
  border: 1px solid darkgray;
}