.EPCLLES {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 128, 0, 0.5);
}

.EPCLLES_1 {
  display: flex;
  flex-direction: column;
  background-color: rgb(150, 73, 147, 0.5);
  text-align: left;
}

.EPCLLES_2 {
  display: flex;
  flex-direction: row;
  background-color: rgb(200, 73, 147, 0.5);  
  margin: 5px;
  justify-content: space-between;
}

.EPCLLES_3 {
  display: flex;
  flex-direction: row;
  background-color: rgb(250, 73, 147, 0.5);  
  margin: 5px;
  justify-content: space-between;
}

.EPCLLES_31 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgb(50, 73, 147, 0.5);  
  margin: 5px;
}
.EPCLLES_32 {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: rgb(100, 73, 147, 0.5);  
  margin: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.EPCLLES_33 {
  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: rgb(150, 73, 147, 0.5);  
  margin: 5px;
  justify-content: right;
  align-items: center;
}
.EPCLLES_331 {
  display: flex;
  flex-direction: column;
  background-color: rgb(200, 73, 147, 0.5);  
  margin: 5px;
  margin-right: 10px;  
  cursor: pointer;
}
.EPCLLES_332 {
  display: flex;
  flex-direction: column;
  background-color: rgb(250, 73, 147, 0.5);  
  margin: 5px;
  margin-left: 10px;
  cursor: pointer;
}