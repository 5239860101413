.app__headerVOS {
    background-color: #636564;
    /* background-color: var(--color-black); */
    /* border: 1px solid var(--color-golden); */
    border-bottom: 2px solid var(--color-golden);
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

.productsLogo {
    display: flex;
    /* gap: 1rem; */
    color: navy;
    /* border: 2px solid red; */
}

.productsLogo img {
    display: flex;    
    justify-content: center;
    align-items:center;
    margin: auto auto auto 0;
    flex: 1;
    height: 80px;
    width: 80px;
    /* border: 2px solid blue; */
}

.productsLogo h1 {
    flex: 3;
    /* border: 2px solid red; */
}

.products {
    display: flex;
    gap: 1rem;
}

.productsIcon {
    font-size: 70px;
    color: maroon;
    margin: auto;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
    .productsLogo img {
        height: 40px;
        width: 40px;    
    }
}
