.PDFV {
  display: flex;
  flex-direction: column;
  background-color: bisque;
  align-items: center;
  height: 100%;
}

.PDFV_A1 {
  display: flex;
}

.PDFV_A2 {
  display: flex;
  flex-direction: row;
  margin: 10px;
  background-color: lightslategray;
  justify-content: space-between;
  width: 100%;
}

.PDFV_A3 {
  color: blue;
  font-weight: 500;
}

