.TI_container {
  display: flex;
  flex-direction: column;
  background-color: gray;
  width: 100%;
  height: 100%;
}

.TI_upload_container {
  display: flex;
  flex-direction: row;
  height: 35px;
}
.TI_upload_filler {
  display: flex;
  flex: 10;
}
.TI_upload {
  display: flex;
  flex: 1;
  font-size: 15px;
  margin: 5px;
  align-items: center;
  justify-content: center;  
  cursor: pointer;
  background-color: rgba(0, 128, 0, 0.5);
  border: 1px solid green;
}

.TI_row {
  display: flex;;
  height: 100%;
  width: 100%;
  background-color: lightblue;
  align-items: center;
  justify-content: center;
}

.TI_editor {
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  background-color: rgb(99, 163, 145);
  align-items: center;
  justify-content: center;
}

.TI_preview {
  display: flex;
  position: relative;
  width: 50%;
  height: 100%;
  border-left: 1px solid black;
  padding: 10px;
  overflow-y: scroll;
  background-color: rgb(40, 99, 82);
}

.TI_editor-input {
  height: 100%;
  width: 100%;
}

.TI_A2 {
  display: flex;  
  background-color: yellowgreen;
}
