.CPS {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 500px;
  background-color: rgba(0,0,0,0.5);
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.CPS_A1, 
.CPS_A2, 
.CPS_A3, 
.CPS_A4
 {
  display: flex;
  background-color: gray;
}

.CPS_A1, 
.CPS_A2, 
.CPS_A3 {
  margin-right: 50px;
}
