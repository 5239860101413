.PITCH_1 {
  display: flex;
  flex-direction: column;
  background-color: rgba(58, 15, 127, 0.5);
}

.PITCH_2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(190, 80, 200, 0.5);
  align-items: center;
  justify-content: center;
  margin: 5px;
  height: 500px;
}

.PITCH_4 {
  display: flex;
  flex-direction: column;
  background-color: rgba(165, 42, 42, 0.5);
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 1000px;
  overflow: scroll;
}

.PITCH_3 {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 128, 0, 0.5);
  justify-content: center;
  align-items: center;
  height: 50px;
}

