.EPPR {
  display: flex;
  flex-direction: column;
  background-color: rgba(113, 61, 9, 0.5);
  /* align-self: center; */
  justify-content: center;
  width: 50%;
  margin-top: 50px;
  /* position: relative; */
  transform: translateX(40%);
}

.EPPR_A26,
.EPPR_A1 {
  display: flex;
  flex-direction: row;
  flex: 20;
  /* background-color: rgba(144, 238, 144, 0.5); */
  font-size: 30px;
  margin: 10px;
  justify-content: center;
}

.EPPR_A20 {
  display: flex;
  flex-direction: column;
  font-size: 15px;  
  flex: 7;
  cursor: pointer;
}
.EPPR_A21 {
  display: flex;
  justify-content: left;
}
.EPPR_A22 {
  display: flex;
  justify-content: left;
  color: blue;
}
.EPPR_A23 {
  display: flex;
  flex: 7;
  flex-direction: column;
  font-size: 15px;  
  cursor: pointer;
}
.EPPR_A24,
.EPPR_A25 {
  display: flex;
  justify-content: right;
}
.EPPR_A25 {
  color: blue;
}

.EPPR_A2 {
  display: flex;
  /* background-color: rgba(177, 144, 238, 0.5); */
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
}

.EPPR_A3 {
  display: flex;
  flex-direction: row;
  /* background-color: rgba(238, 144, 216, 0.5); */
  margin: 20px;
}

.EPPR_A3_1 {
  display: flex;
  flex: 3;
  background-color: rgba(235, 106, 205, 0.5);
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}

.EPPR_A3_2 {
  display: flex;
  flex: 10;
  background-color:  rgba(198, 216, 242, 0.5);
  align-items: center;
}

.EPPR_A3_2_2 {
  display: flex;
  flex: 10;
  color: green;
  /* font-size: 20px; */
  /* background-color: rgba(113, 61, 9, 0.4); */
  background-color:  rgba(198, 216, 242, 0.7);
  justify-content: left;
  padding-left: 10px;
  align-items: center;
  /* border: 1px solid green; */
  align-items: center;
}

.EPPR_A6 {
  display: flex;
  flex: 10;
  flex-direction: row;
  background-color: lightblue;
  align-self: center;
  justify-content: space-around;
  width: 50%;
  margin: 20px;
}

.EPPR_A4 {
  display: flex;
  flex: 10;
  flex-direction: row;
  background-color: lightblue;
  align-self: center;
  justify-content: space-around;
  width: 50%;
  /* margin: 20px; */
}

.EPPR_A4_1 {
  display: flex;
  margin: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.EPPR_A4_2 {
  display: flex;
  margin: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.EPPR_A4_3 {
  display: flex;
  margin: 5px;
  color: red;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.EPPR_A4_4 {
  display: flex;
  margin: 5px;
  color: green;
  cursor: not-allowed;
  align-items: center;
  justify-content: center;
}
.EPPR_A4_5 {
  display: flex;
  margin: 5px;
  color: darkgray;
  cursor: not-allowed;
  align-items: center;
  justify-content: center;
}
.EPPR_A4_5_unavailable {
  display: flex;
  margin: 5px;
  color: salmon;
  cursor: not-allowed;
  align-items: center;
  justify-content: center;
}
.unavailable_pricing {
  display: flex;
  flex-direction: column;
  color: salmon;
  cursor: not-allowed;
  margin: 20px;
}


.EPPR_A5 {
  display: flex;
  font-size: 15px;
  justify-content: center;
}

.EPPR_A5_1 {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.EP_A5_4 {
  display: flex;
  flex-direction: row;
}
.EP_A5_3 {
  display: flex;
  flex: 1;
}
.EPPR_A5_4 {
  display: flex;
  flex-direction: row;
  flex: 4.5;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin: 20px;
  background-color: rgba(198, 216, 242, 0.5);
}
.EPPR_A5_4:hover {
  border: 2px solid rgba(198, 216, 242, 0.5);
}