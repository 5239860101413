.one {
    display: flex;
}
.oneOne {
    flex: 1;
}
.containerLogin2 {
    flex: 1;
    display: flex;
    /* border: 2px solid red; */
    justify-content: center;
    align-items: center;
}
.oneTwo {
    flex: 1
}

.wrappedContent {
    width:500px;
    /* overflow-wrap:break-word; */
    margin: 20px;
    border: 2px solid black;
    overflow: scroll;
}