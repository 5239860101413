.EPI {
  display: flex;
  flex-direction: column;
  background-color: rgba(113, 61, 9, 0.5);
  /* align-self: center; */
  justify-content: center;
  width: 50%;
  margin-top: 50px;
  /* position: relative; */
  transform: translateX(40%);
}

.EPI_A1 {
  display: flex;
  flex-direction: row;
  /* background-color: rgba(144, 238, 144, 0.5); */
  font-size: 30px;
  margin: 30px;
  /*align-self: flex-start;*/
  justify-content: center;
  width: 90%
}

.EPI_A21 {
  display: flex;
  flex-direction: column;
  flex: 20;
  font-size: 30px;
}
.EPI_A22 {
  display: flex;
  flex-direction: column;
  flex: 8;
  justify-content: right;
  align-items: center;
  font-size: 15px;
}
.EPI_A23 {
  display: flex;
  flex-direction: column;
  font-size: 15px;  
  cursor: pointer;
}
.EPI_A24,
.EPI_A25 {
  display: flex;
  justify-content: right;
}
.EPI_A25 {
  color: blue;
}

.EPI_A2 {
  display: flex;
  /* background-color: rgba(177, 144, 238, 0.5); */
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
}

.EPI_A3 {
  display: flex;
  flex-direction: row;
  /* background-color: rgba(238, 144, 216, 0.5); */
  margin: 30px;
}

.EPI_A3_1 {
  display: flex;
  flex: 3;
  /* background-color: rgba(235, 106, 205, 0.5); */
  background-color: rgba(235, 106, 205, 0.5);
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}

.EPI_A3_2 {
  display: flex;
  flex: 10;
  background-color:  rgba(198, 216, 242, 0.5);
  align-items: center;
}

.EPI_A3_2_2 {
  display: flex;
  flex: 10;
  color: green;
  /* font-size: 20px; */
  /* background-color: rgba(113, 61, 9, 0.4); */
  background-color:  rgba(198, 216, 242, 0.7);
  justify-content: left;
  padding-left: 10px;
  /* border: 1px solid green; */
  align-items: center;
}
.EPI_A3_2_3 {
  display: flex;
  flex: 10;
  color: rgb(128, 0, 0);
  cursor: not-allowed;
  /* font-size: 20px; */
  /* background-color: rgba(113, 61, 9, 0.4); */
  background-color:  rgba(198, 216, 242, 0.5);
  justify-content: left;
  padding-left: 10px;
  /* border: 1px solid green; */
  align-items: center;
}
.EPI_A3_2_4 {
  display: flex;
  flex: 3;
  cursor: not-allowed;
  /* background-color: rgba(235, 106, 205, 0.5); */
  background-color: rgba(128, 0, 0, 0.5);
  align-items: center;
  justify-content: left;
  padding-left: 10px;
}

.EPI_A11 {
  display: flex;
  flex: 10;
  flex-direction: row;
  background-color: lightblue;
  align-self: center;
  justify-content: space-around;
  width: 50%;
  margin: 20px;
}

.EPI_A11 button {
  border-radius: 5px;
  background: none;
  border: 1px solid salmon;
  justify-content: center;
  align-items: center;
}
.EPI_A4 {
  display: flex;
  flex: 10;
  flex-direction: row;
  background-color: lightblue;
  align-self: center;
  justify-content: space-around;
  width: 50%;
  margin: 20px;
}

.EPI_A4_1 {
  display: flex;
  margin: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.EPI_A4_2 {
  display: flex;
  margin: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.EPI_A4_3 {
  display: flex;
  margin: 5px;
  color: green;
  cursor: not-allowed;
  align-items: center;
  justify-content: center;
}
.EPI_A4_4 {
  display: flex;
  margin: 5px;
  color: red;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.EPI_A4_5 {
  display: flex;
  margin: 5px;
  cursor: not-allowed;
  color: darkgray;
  align-items: center;
  justify-content: center;
}
.EPI_A4_6 {
  display: flex;
  margin: 5px;
  cursor: not-allowed;
  color: salmon;
  align-items: center;
  justify-content: center;  
}
.EPI_A4_10 {
  display: flex;
  margin: 5px;
  color: salmon;
  align-items: center;
  justify-content: center;
}
.EPI_A4_11 {
  display: flex;
  flex-direction: column;
  margin: 5px;
  align-items: center;
  justify-content: center;
}
.EPI_A4_12 {
  display: flex;
  margin: 5px;
  color: black;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.EPI_A4_old {
  display: flex;
  flex: 10;
  flex-direction: row;
  background-color: lightblue;
  align-self: center;
  justify-content: space-around;
  width: 50%;
  margin: 20px;
}

.EPI_A4_1_old {
  display: flex;
  margin: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.EPI_A4_2_old {
  display: flex;
  margin: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 128, 0, 0.7);
}

.EPI_A5 {
  display: flex;
  font-size: 15px;
  justify-content: center;
}

.EPI_A6 {
  display: flex;
  font-size: 15px;
  justify-content: center;
  margin: 10px;
  padding: 10px;
  background-color: rgba(35, 56, 35, 0.5);
}

.EPI_A5_1 {
  display: flex;
  flex-direction: row;
  margin: 20px;
}

.EP_A5_4 {
  display: flex;
  flex-direction: row;
}
.EP_A5_3 {
  display: flex;
  flex: 1;
}
.EPI_A5_4 {
  display: flex;
  flex-direction: row;
  flex: 4.5;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  margin: 20px;
  background-color: rgba(198, 216, 242, 0.5);
}
.EPI_A5_4:hover {
  border: 2px solid rgba(198, 216, 242, 0.5);
}