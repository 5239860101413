.overlay__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
  }
  
  .overlay__container {
    background-color: lightgray;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 10;
    padding: 10px;
    width: fit-content;
    height: fit-content;
  }
  
  .overlay__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .overlay__close:after {
    display: inline-block;
    content: "\00d7";
    width: 20px;
    /* border: 2px solid red; */
  }
  
  .overlay__close {
    border: none;
    background-color: transparent;
    font-size: 15px;
    cursor: pointer;
    /* border: 2px solid green; */
  }
  
  