.PPAGE_0 {
  display: flex;
  flex-direction: column;
  background-color: rgba(70, 161, 136, 0.5);
  height: 800px;
  justify-content: center;
  align-items: center;
}

.PPAGE_1 {
  display: flex;
  flex-direction: row;
  background-color: rgba(128, 0, 0, 0.5);
  height: 500px;
}

.PPAGE_2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 75, 55, 0.5);  
  flex: 15;
}

.PPAGE_3 {
  display: flex;
  flex-direction: column;
  background-color: rgba(7, 49, 105, 0.5);  
  align-self: center;
  margin: 10px;
}

.PPAGE_4 {
  display: flex;
  flex-direction: column;
  background-color: rgba(11, 99, 77, 0.5);  
  text-align: center;
  margin: 10px;
}

.PPAGE_5 {
  display: flex;
  flex-direction: column;
  background-color: rgba(151, 16, 84, 0.5);
  flex: 30;
  align-items: center;
  justify-content: center;
}

.PPAGE_6 {
  display: flex;
  flex-direction: column;
  background-color: rgba(32, 105, 12, 0.5);
  text-align: center;
  margin: 10px;
  width: 500px;
}

.PPAGE_7 {
  display: flex;
  flex-direction: column;
  background-color: rgba(92, 78, 11, 0.5);
}

.PPAGE_8 {
  display: flex;
  flex-direction: column;
  background-color: rgba(36, 121, 142, 0.5);
  margin: 5px;
  width: 500px;
}

.PPAGE_10 {
  display: flex;
  flex-direction: row;
  width: 500px;
  justify-content: space-around;
  background-color: rgba(53, 10, 10, 0.5);
  margin-top: 40px;
}