.CED {
  display: flex;
  flex-direction: column;
  /* background-color: brown; */
  /* margin: 5px; */
  /* height: 80px; */
  /* width: 80px; */
  align-self: center;
  justify-content: center;  
  cursor: pointer;  
}

.CED:hover {
  border-bottom: 1px solid var(--color-borderpink);
  /* transition: .5s ease; */
}

.CED_A1 {
  display: flex;
  flex: 1;
  /* background-color: gray;  */
  align-self: center;
  justify-content: center;  
  align-items: center;
}

.CED_A2 {
  display: flex;
  flex: 1;
  /* font-size: 12px; */
  /* background-color: turquoise;  */
  align-self: center;
  align-items: center;
  justify-content: center;  
  /* color: brown;*/
}

