.app__headerIntro {
    background-color: #404443;
    /* background-color: var(--color-black); */
    /* border: 1px solid var(--color-golden); */
    border-bottom: 2px solid var(--color-golden);
    flex-direction: column;
}

.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    line-height: 117px;
    font-size: 90px;
}

.app__header-img img {
    width: 80%;
}

.swiftLogo {
    display: flex;
    gap: 1rem;
}

.awsLogoIcon {
    font-size: 100px;
    color: var(--color-golden);
    margin: auto;
}

.introImage {
    display: flex;
    gap: 3rem;
    margin-top: 100px;
    flex-direction: row;
    color: white;
    justify-content: center;
    align-items: center;
}
.introImage1 {
    width: 130px;
    height: 130px;
}
.introImage2 {
    width: 500px;
    height: 150px;
}

.app__wrapper_info_Intro {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: 2px solid purple; */
}

.angleDownLogo {
    color: var(--color-golden);;
    font-size: 30px;
    margin-left: 5px;
}

@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
    .introImage1 {
        width: 50px;
        height: 50px;
    }
    .introImage2 {
        width: 200px;
        height: 70px;
    }
}
