.RTLI_1 {
  display: flex;
  flex-direction: row;
  color: darkgoldenrod;
}

.RTLI_2,
.RTLI_3 {
  display: flex;
  flex: 10;
  align-items: center;
}

.RTLI_2 {
  justify-content: right;
  margin-right: 10px;
}
.RTLI_3 {
  justify-content: left;
  margin-left: 10px;
}
