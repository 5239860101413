.CT {
  display: flex;  
  flex-direction: column;
  flex-wrap: wrap;
  background-color: rgba(211, 211, 211, 0.35);
  width: 100%;
  border: 2px solid darkgray;
}

.CT_Header {
  display: flex;
  /* background-color: rgba(8, 69, 191, 0.35); */
  background-color: rgba(211, 211, 211, 0.35);
  margin: 1px;
  width: 100%;
}

.CT_Header_A1 {
  display: flex;
  flex: 3;
  /* background-color:burlywood; */
  margin: 1px;
}

.CT_Header_A2 {
  display: flex;
  flex: 1;
  /* background-color: red; */
  margin: 1px;  
  justify-content: flex-end;
  align-self: center;
  margin-right: 5px;
}

.CT_Body {
  display: flex;
  flex-wrap: wrap;
  /* background-color: lightgray; */
  margin: 2px;
  width: 100%;
}

.CT_A1 {
  display: flex;
  /* background-color: blueviolet; */
  margin: 2px;
  width: 100%;
}

.CT_A2 {
  display: flex;
  /* background-color:yellowgreen; */
  margin: 2px;
  width: 100%;
}

.CT_A3 {
  display: flex;
  /* background-color: gray; */
  margin: 2px;
  width: 100%;
}