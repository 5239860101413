.social-container {
  display: flex;;
  flex-direction: row;
  justify-content: space-around;
  /*border: 4px solid var(--color-gray);*/
  background-color: var(--color-golden);
  padding: 2rem 0;
}

a.social {
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #eb3223;
}

a.facebook {
  color: #4968ad;
}

a.twitter {
  color: #49a1eb;
}

a.instagram {
  color: purple;
}
