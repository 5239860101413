.CLASS_LESSON {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 128, 0, 0.5);
}

.CLASS_LESSON_1 {
  display: flex;
  flex-direction: column;
  background-color: rgb(0, 0, 147, 0.5);
  border: 2px solid purple; 
  margin: 5px;
}

.CLASS_LESSON_2 {
  display: flex;
  flex-direction: column;
  background-color: rgba(241, 141, 65, 0.5);  
  border: 2px solid purple; 
  margin: 5px;
}

.CLASS_LESSON_3 {
  display: flex;
  flex-direction: column;
  background-color: rgba(196, 207, 79, 0.806);  
  border: 2px solid purple; 
  margin: 5px;
}

.CLASS_LESSON_4 {
  display: flex;
  flex-direction: column;
  background-color: rgba(141, 86, 237, 0.806);  
  border: 2px solid purple; 
  margin: 5px;
}