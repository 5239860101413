.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);
    /* border: 1px solid red; */
    padding: 1rem 2rem;
    transition: all .7s ease-in;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border: 1px solid cyan; */
    cursor: pointer;
    height: 45px;
}

.app__navbar-logo:hover {
    border-bottom: 1px solid var(--color-borderpink);
    transition: .5s ease;
}

.app__navbar-logo div {
    font-size: 30px;
    color: var(--color-olive5);
    /* border: 1px solid green; */
    margin-left: 0.2rem;
}

.app__navbar-logo img {
    width: 40px;
    height: 40px;
    /* border: 1px solid var(--color-golden) */
}

.app__navbar-links {
    display: flex;
    flex-direction: row;
    flex: 1;
    display: flex;
    justify-content:space-between;
    align-items: center;

    list-style: none;
    cursor: pointer;
    /* border: 1px solid var(--color-golden); */
}

.app__navbar-links li div {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li {
    flex: 1;
    display: flex;
    margin: 0 1rem;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.app__navbar-links .p__opensans_filler {
    flex: 2;
    /* border: 1px solid green; */
}

.app__navbar-links li div:hover {
    color: var(--color-grey);
}

.app__navbar-links li:hover {
    color: var(--color-grey);
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid var(--color-golden) */
}

.app__navbar-login div {
    margin: 0 0.25rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login div:hover {
    margin: 0 0.25rem;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid var(--color-borderpink);
    transition: .5s ease;
}

.app__navbar-login a {
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login-vertbar {
    width: 1px;
    height: 30px;
    background: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li div {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }
    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }
    .app__navbar-login {
        display: none;
    }
    .app__navbar-logo img {
        width: 35px;
        height: 35px;
    }
}

.openModalBtn {
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 6px;
    background-color: cornflowerblue;
    color: white;
    cursor: pointer;
  }